/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


$.fn.slideFadeToggle  = function(speed, easing, callback) {
  return this.animate({opacity: 'toggle', height: 'toggle'}, 300);
}; 
// Toggle open and close nav styles on click
$('#nav-toggle').click(function(e) {
  e.preventDefault();
  $('.navigation-wrapper').slideFadeToggle();
  $(this).find('.nav-toggle-bars').toggleClass('active');
});

$('.slider-motor').slick({
  nextArrow: '<i class="fas fa-angle-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fas fa-angle-left slick-arrow arrow-left"></i>',
  asNavFor: '.slider-motor-nav'
});

$('.slider-motor-nav').slick({
  arrows: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  asNavFor: '.slider-motor',
  focusOnSelect: true,
  swipeToSlide: true,
});

$('.gallery-motor').magnificPopup({
  type: 'image',
  gallery:{
    enabled:true
  }
});

$('.slider-hero').slick({
  nextArrow: '<i class="fas fa-angle-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fas fa-angle-left slick-arrow arrow-left"></i>',
  asNavFor: '.slider-hero-nav',
  autoplay: true,
  autoplaySpeed: 7000,
});

$('.slider-hero-nav').slick({
  arrows: false,
  asNavFor: '.slider-hero',
  vertical: true,
  slidesToShow: 2,
});

$('.popup-inline').on('click', function (event) {
    event.preventDefault();
    var theGoodStuff = $(this).attr('href');
    var formsubject = $(this).attr('popup-form-motor');
    $('.motor-invullen input').val(formsubject);
    $('.motor-url input').val(window.location.href);
    $.magnificPopup.open({
        items: {
          src: theGoodStuff,
        },
        type: 'inline'
    });
});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
